import React from 'react'
// import PropTypes from 'prop-types'

import Hole from './Hole'

const Canvas = () => {
  const canvasRef = React.useRef(null)
  // const [helpers, setHelpers] = React.useState({})
  const [canvasReady, setCanvasReady] = React.useState(false)
  React.useEffect(() => {
    setCanvasReady(true)
  }, [])

  return (
    <div className="Canvas__container">
      <canvas
        id="canvas"
        className="Canvas"
        ref={canvasRef}
      />
      {canvasReady && (
        <Hole
          canvas={canvasRef.current}
          ctx={canvasRef.current.getContext('2d')}
        />
      )}
    </div>
  )
}

// Canvas.propTypes = {
//   holeProps: PropTypes.object,
// }

export default Canvas
