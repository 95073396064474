import React from 'react'
import PropTypes from 'prop-types'

import HoleTouch from './HoleTouch.jsx'
import HoleButtons from './HoleButtons.jsx'

// THE COMPONENT
// -----------------
const HoleInterface = ({ holeProps, holeSize, setHoleProps }) => {
  return (
    <div className="HoleInterface">
      <HoleTouch
        holeProps={holeProps}
        holeSize={holeSize}
        setHoleProps={setHoleProps}
      />
      <HoleButtons
        holeProps={holeProps}
        setHoleProps={setHoleProps}
      />
    </div>
  )
}

HoleInterface.propTypes = {
  holeSize: PropTypes.object.isRequired,
  holeProps: PropTypes.object.isRequired,
  setHoleProps: PropTypes.func.isRequired,
}

export default HoleInterface
