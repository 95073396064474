import React from 'react'

import { saveAs } from 'file-saver'

const DownloadHole = () => {
  const downloadHole = () => {
    const canvas = document.getElementById('canvas')
    canvas.toBlob((blob) => {
      saveAs(blob, 'My Own Hole.png')
    })
  }

  return (
    <button
      className="HoleButtons_download  button"
      onClick={downloadHole}
      title="Download hole"
    >
      <span className="button__svg  button__download" />
    </button>
  )
}

DownloadHole.propTypes = {

}

export default DownloadHole
