import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Canvas from '../components/canvas.jsx'

const IndexPage = () => (
  <Layout>
    <SEO title="Design Your Own Hole" />
    <Canvas />
  </Layout>
)

export default IndexPage
