import React from 'react'
import PropTypes from 'prop-types'
import Color from 'color'
import {
  useWindowSize,
} from '@react-hook/window-size/throttled'

import HoleInterface from './HoleInterface.jsx'

const dims = {
  WIDTH: 0,
  HEIGHT: 0,
}

const initialProps = {
  color: {
    bg: 'blue',
    hole: 'yellow',
  },
  colorOptions: ['blue', 'yellow', 'red'],
  size: {
    height: 60,
    width: 20,
  },
  topPercent: 0.15,
  minHeight: 2,
  maxHeight: 70,
  minWidth: 4,
  maxWidth: 90,
  strokeWidth: 2,
  capRatio: 2.5,
}

const setWindowDimensions = (WIDTH, HEIGHT) => {
  dims.WIDTH = WIDTH
  dims.HEIGHT = HEIGHT
}

const clearCanvas = (canvas, ctx) => () => {
  ctx.clearRect(0, 0, dims.WIDTH, dims.HEIGHT)
}

const drawBg = (canvas, ctx) => (color) => {
  ctx.fillStyle = color
  ctx.fillRect(0, 0, dims.WIDTH, dims.HEIGHT)
}

const resizeCanvas = (canvas, ctx) => (WIDTH, HEIGHT) => {
  clearCanvas(canvas, ctx)()
  // Stop here if no change in canvas size
  const { WIDTH: oldWidth, HEIGHT: oldHeight } = dims
  if (WIDTH === oldWidth && HEIGHT === oldHeight) return
  setWindowDimensions(WIDTH, HEIGHT)
  const { devicePixelRatio } = window
  canvas.width = WIDTH * devicePixelRatio
  canvas.height = HEIGHT * devicePixelRatio
  ctx.scale(devicePixelRatio, devicePixelRatio)
  ctx.lineWidth = initialProps.strokeWidth
}

// GROUD
// ----------
// const drawHoleGround = (canvas, ctx) => (color, width, height, x, y) => {
//   ctx.fillStyle = 'hsla(0, 0%, 100%, 0.5)'
//   const radiusX = width * 4
//   const radiusY = radiusX / initialProps.capRatio
//   const groundX = x + (width / 2)
//   const groundY = y - (radiusY / 2)
//   ctx.beginPath()
//   ctx.ellipse(groundX, groundY, radiusX, radiusY, 0, 0, 2 * Math.PI)
//   ctx.fill()
// }

// GRADIENT
// ----------
const setGradFill = (canvas, ctx) => (color, width, height, x, y) => {
  const grd = ctx.createLinearGradient(x, y, x + width, y)
  const maxDarkness = 0.4
  let alpha
  let gradColor
  let darkness
  for (let t = 0; t <= 1; t += 0.02) {
    alpha = 1 - Math.sin(t * Math.PI)
    darkness = maxDarkness * alpha
    gradColor = Color(color).darken(darkness).hsl()
    grd.addColorStop(t, gradColor)
  }
  ctx.fillStyle = grd
}


// WALL
// ----------
const drawHoleWallBg = (canvas, ctx) => (color, width, height, x, y) => {
  setGradFill(canvas, ctx)(color, width, height, x, y)
  ctx.fillRect(x, y, width, height)
}

const drawHoleWallOutline = (canvas, ctx) => (color, width, height, x, y) => {
  ctx.beginPath()
  ctx.moveTo(x, y + height)
  ctx.lineTo(x, y)
  ctx.lineTo(x + width, y)
  ctx.lineTo(x + width, y + height)
  ctx.stroke()
}

const drawHoleWall = (canvas, ctx) => (color, width, height, x, y) => {
  drawHoleWallBg(canvas, ctx)(color, width, height, x, y)
  drawHoleWallOutline(canvas, ctx)(color, width, height, x, y)
}

// ENDS
// ----------
const drawHoleEnd = (canvas, ctx) => (color, width, height, x, y) => (end) => {
  const radiusX = width / 2
  const radiusY = height / 2
  const capX = x + (width / 2)
  const capY = y
  // Draw cap
  if (end === 'top') {
    const capColor = 'black'
    ctx.fillStyle = capColor
    ctx.beginPath()
    ctx.ellipse(capX, capY, radiusX, radiusY, 0, 0, 2 * Math.PI)
    ctx.fill()
    ctx.stroke()
    return
  }
  // Draw bottom
  const bottomX = capX
  const bottomY = y
  setGradFill(canvas, ctx)(color, width, height, x, y)
  ctx.beginPath()
  ctx.ellipse(bottomX, bottomY, radiusX, radiusY, 0, 0, 2 * Math.PI)
  ctx.fill()
  ctx.stroke()
}

// THE HOLE
// ---------------------
const drawHole = (canvas, ctx) => (color, width, height) => {
  const { WIDTH, HEIGHT } = dims
  const holeWidth = WIDTH * (width / 100)
  const holeHeight = HEIGHT * (height / 100)
  const capWidth = holeWidth
  const capHeight = capWidth / initialProps.capRatio
  const x = (WIDTH / 2) - (holeWidth / 2)
  const y = (HEIGHT * initialProps.topPercent) + (capHeight / 2)
  // Bottom
  drawHoleEnd(canvas, ctx)(color, capWidth, capHeight, x, y + holeHeight)('bottom')
  // Wall
  drawHoleWall(canvas, ctx)(color, holeWidth, holeHeight, x, y)
  // // Ground
  // drawHoleGround(canvas, ctx)(color, holeWidth, holeHeight, x, y)
  // Top
  drawHoleEnd(canvas, ctx)(color, capWidth, capHeight, x, y)('top')
  // Return total height
  return {
    height: holeHeight + capHeight,
    width: holeWidth,
  }
}

// INITIAL
// ----------------
const init = (canvas, ctx) => (props) => (WIDTH, HEIGHT) => (setHoleSize) => {
  const { color, size: { width, height } } = props
  resizeCanvas(canvas, ctx)(WIDTH, HEIGHT)
  drawBg(canvas, ctx)(color.bg)
  const holeSize = drawHole(canvas, ctx)(color.hole, width, height)
  setHoleSize(holeSize)
}

// THE COMPONENT
// ----------------------
const Hole = ({ canvas, ctx }) => {
  const [WIDTH, HEIGHT] = useWindowSize()
  const [holeProps, setHoleProps] = React.useState(initialProps)
  const [holeSize, setHoleSize] = React.useState({})
  // On resize
  React.useEffect(() => {
    init(canvas, ctx)(holeProps)(WIDTH, HEIGHT)(setHoleSize)
  }, [WIDTH, HEIGHT])
  // On props change
  React.useEffect(() => {
    init(canvas, ctx)(holeProps)(WIDTH, HEIGHT)(setHoleSize)
  }, [holeProps])

  return (
    <HoleInterface
      holeProps={holeProps}
      holeSize={holeSize}
      setHoleProps={setHoleProps}
    />
  )
}

Hole.propTypes = {
  canvas: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
  ]),
  ctx: PropTypes.object,
  holeProps: PropTypes.object,
}

export default Hole
