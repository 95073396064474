import React from 'react'
import PropTypes from 'prop-types'

const Header = ({ siteTitle, siteDescription }) => {
  const [aboutOn, setAboutOn] = React.useState(false)

  const toggleAbout = () => {
    setAboutOn(!aboutOn)
  }

  return (
    <>
      <header className="Header">
          {/* <div
            className="Header__hole  button"
          >
            <span className="button__svg  button__hole" />
          </div> */}
          <button
            className="Header__aboutButton  button"
            onClick={toggleAbout}
            title="About"
          >
            <span className="button__svg  button__about" />
          </button>
      </header>
      <section
        className="AboutPage"
        style={{
          display: aboutOn ? 'flex' : 'none',
        }}>
        <a
          role="button"
          className="AboutPage__bg"
          onClick={toggleAbout}
        />
        <div className="AboutPage__inner">
          <h1 className="AboutPage__title">{siteTitle}</h1>
          <p>{siteDescription}</p>
        </div>
        {/* Credit */}
        <p className="AboutPage__credit">
          <a
            href="https://www.elwyn.co"
            target='_blank'
            rel='noopener noreferrer'
          >
              Site by ben elwyn
          </a>
        </p>
      </section>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  siteDescription: PropTypes.string.isRequired,
}

export default Header
