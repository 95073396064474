import React from 'react'
import PropTypes from 'prop-types'
import {
  useWindowSize,
} from '@react-hook/window-size/throttled'

import Star from '../../static/gfx/star.inline.svg'

const clamp = (num, clamp, higher) =>
  (higher ? Math.min(Math.max(num, clamp), higher) : Math.min(num, clamp))

const getHoleTouchStyle = (holeProps, holeSize) => {
  const { topPercent } = holeProps
  const { width: holeWidth, height: holeHeight } = holeSize
  const top = `${topPercent * 100}%`
  const height = `${holeHeight}px`
  const width = `${holeWidth}px`
  return {
    position: 'absolute',
    top,
    right: '50%',
    width,
    height,
    transform: 'translate(50%, 0)',
  }
}

let holePropsClone
const cloneHoleProps = (holeProps) => {
  const clone = JSON.parse(JSON.stringify(holeProps))
  holePropsClone = clone
}

// THE COMPONENT
// -----------------
const HoleTouch = ({ holeProps, holeSize, setHoleProps }) => {
  const [WIDTH, HEIGHT] = useWindowSize()
  const [panning, setPanning] = React.useState(false)
  // HANDLE PAN EVENT
  const handlePan = (e) => {
    setPanning(true)
    e.srcEvent.stopPropagation()
    e.preventDefault()
    const {
      deltaX,
      deltaY,
    } = e
    const weight = 100
    const {
      size: { width, height },
      minHeight,
      maxHeight,
      minWidth,
      maxWidth,
    } = holePropsClone
    const newWidth = width + ((deltaX / WIDTH) * weight)
    const newHeight = height + ((deltaY / HEIGHT) * weight)
    const newHoleProps = {
      ...holePropsClone,
      size: {
        width: clamp(newWidth, minWidth, maxWidth),
        height: clamp(newHeight, minHeight, maxHeight),
      },
    }
    setHoleProps(newHoleProps)
  }

  // ON PAN END
  const panEnd = () => {
    setPanning(false)
  }

  // SETUP HAMMER JS
  const setupHammer = (el, setHam) => {
    // eslint-disable-next-line
    const Hammer = require('hammerjs')
    const hammerOptions = {
      preventDefault: true,
      domEvents: true,
    }
    // Setup hammer instance
    const ham = new Hammer.Manager(el, hammerOptions)
    ham.add(new Hammer.Pan())
    setHam(ham)
    // Listen to events
    ham
      .on('panup', handlePan)
      .on('pandown', handlePan)
      .on('panleft', handlePan)
      .on('panright', handlePan)
      .on('panend', panEnd)
  }

  const killHammer = () => {
    // eslint-disable-next-line
    ham
      .off('panup', handlePan)
      .off('pandown', handlePan)
      .off('panleft', handlePan)
      .off('panright', handlePan)
      .off('panend', panEnd)
  }

  const [ham, setHam] = React.useState({})
  const hammerEl = React.useRef(null)
  React.useEffect(() => {
    if (typeof window === 'undefined') return
    setupHammer(hammerEl.current, setHam)
    return () => {
      killHammer()
    }
  }, [])

  // Update hole props clone at end of pan
  const touched = React.useRef(false)
  React.useEffect(() => {
    if (panning) {
      touched.current = true
      return
    }
    cloneHoleProps(holeProps)
  }, [panning, holeProps])

  return (
    <div
      className="HoleTouch"
      ref={hammerEl}
      style={getHoleTouchStyle(holeProps, holeSize)}
    >
      {!touched.current && (
        <div className="HoleTouch__helper">
          <span className="HoleTouch__helper_text">
            Touch me,<br/>
            and drag
          </span>
          <Star />
        </div>
      )}
    </div>
  )
}

HoleTouch.propTypes = {
  holeSize: PropTypes.object.isRequired,
  holeProps: PropTypes.object.isRequired,
  setHoleProps: PropTypes.func.isRequired,
}

export default HoleTouch
