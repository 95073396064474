import React from 'react'
import PropTypes from 'prop-types'

import DownloadHole from './DownloadHole.jsx'

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

const getColors = ({ colorOptions }) => {
  return shuffle(colorOptions)
}

const HoleButtons = ({ holeProps, setHoleProps }) => {
  const swapColors = () => {
    const { color: { bg: currentBg, hole: currentHole } } = holeProps
    const [bg, hole] = getColors(holeProps)
    // Redo if same colors
    if (bg === currentBg && hole === currentHole) {
      swapColors()
      return
    }
    const newHoleProps = {
      ...holeProps,
      color: { bg, hole },
    }
    setHoleProps(newHoleProps)
  }

  return (
    <div className="HoleButtons">
      <button
        className="HoleButtons_color button"
        onClick={swapColors}
        title="Swap colors"
      >
        <span className="button__svg  button__refresh" />
      </button>
      <DownloadHole />
    </div>
  )
}

HoleButtons.propTypes = {
  holeProps: PropTypes.object.isRequired,
  setHoleProps: PropTypes.func.isRequired,
}

export default HoleButtons
